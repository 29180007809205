import actions from './actions'
import mutations from './mutations'

const state = {
  dedupeAgentId: null,
  searchAgentId: null,

  agentRule: {
    data: null,
    updating: false,
    error: false,
  },

  directorRules: [],
  directorRule: null,

  presentationRules: {
    data: null,
    loading: false,
    error: false,
  },

  rulesMetadata: {},
  // rulesMetadata: {
  //   Filter: {
  //     Account: "",
  //     Lead: "",
  //     ...
  //   },
  //   MatchCriteria: {
  //     Account: "",
  //     Lead: "",
  //      ...
  //   },
  //   ...
  // },

  entities: [],
  segmentationReports: [],
  convertLeadStatuses: [],
  operators: [],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
