export default {
  SET_AGENT_IDS: 'SET_AGENT_IDS',
  SET_AGENT_RULE: 'SET_AGENT_RULE',
  UPDATING_AGENT_RULE_PENDING: 'UPDATING_AGENT_RULE_PENDING',
  UPDATING_AGENT_RULE_SUCCESS: 'UPDATING_AGENT_RULE_SUCCESS',
  UPDATING_AGENT_RULE_ERROR: 'UPDATING_AGENT_RULE_ERROR',

  SET_DIRECTOR_RULES: 'SET_DIRECTOR_RULES',
  SET_DIRECTOR_RULE: 'SET_DIRECTOR_RULE',
  SET_RULE: 'SET_RULE',

  SET_PRESENTATION_RULES: 'SET_PRESENTATION_RULES',
  DELETING_PRESENTATION_PENDING: 'DELETING_PRESENTATION_PENDING',
  DELETING_PRESENTATION_SUCCESS: 'DELETING_PRESENTATION_SUCCESS',
  DELETING_PRESENTATION_ERROR: 'DELETING_PRESENTATION_ERROR',
  CREATING_PRESENTATION_SUCCESS: 'CREATING_PRESENTATION_SUCCESS',
  UPDATING_PRESENTATION_SUCCESS: 'UPDATING_PRESENTATION_SUCCESS',

  SET_RULES_METADATA: 'SET_RULES_METADATA',

  SET_RULES: 'SET_RULES',
  SET_SEGMENTATION_REPORTS: 'SET_SEGMENTATION_REPORTS',
  SET_CONVERT_LEAD_STATUSES: 'SET_CONVERT_LEAD_STATUSES',
  SET_OPERATORS: 'SET_OPERATORS',
}
