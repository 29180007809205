import actions from './actions'
import mutations from './mutations'

const state = {
  // main toast
  isToastVisible: false,
  toast: {
    color: 'black',
    message: '',
    timeout: 3000,
  },
  isIndexing: false,
  snackbars: [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
