export default {
  SET_JWT: 'SET_JWT',
  SET_HEROKU_TOKEN: 'SET_HEROKU_TOKEN',
  SET_HEROKU_DEV_TOKEN: 'SET_HEROKU_DEV_TOKEN',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_CONTEXT_MESSAGE: 'SET_CONTEXT_MESSAGE',
  SIGN_OUT: 'SIGN_OUT',
  SET_SESSION_EXPIRED: 'SET_SESSION_EXPIRED',
  SET_LAST_REQUEST_TIMESTAMP: 'SET_LAST_REQUEST_TIMESTAMP',
  SET_STEPPER: 'SET_STEPPER',
}
