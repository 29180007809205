import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SET_JWT](state, jwt) {
    state.jwt = jwt
    localStorage.setItem('jwt', jwt)
  },
  [mutationTypes.SET_CURRENT_USER](state, currentUser) {
    state.user = currentUser
  },
  [mutationTypes.SET_CONTEXT_MESSAGE](state, message) {
    state.contextMessage = message
  },
  [mutationTypes.SIGN_OUT]() {
    localStorage.removeItem('jwt')
    location.reload()
  },
  [mutationTypes.SET_SESSION_EXPIRED](state, value) {
    state.isSessionExpired = value
  },
  [mutationTypes.SET_LAST_REQUEST_TIMESTAMP](state, value) {
    state.lastRequestTimestamp = value
  },
  [mutationTypes.SET_STEPPER](state, payload) {
    state.isHerokuRequired = payload.isHerokuRequired
    state.isStepperFinished = payload.isStepperFinished
  },
}
