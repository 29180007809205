import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SET_AGENT_IDS]: (state, { searchAgentId, dedupeAgentId }) => {
    state.searchAgentId = searchAgentId
    state.dedupeAgentId = dedupeAgentId
  },
  [mutationTypes.SET_AGENT_RULE]: (state, payload) => {
    state.agentRule.data = payload
  },
  [mutationTypes.UPDATING_AGENT_RULE_PENDING]: (state) => {
    state.agentRule.error = false
    state.agentRule.updating = true
  },
  [mutationTypes.UPDATING_AGENT_RULE_SUCCESS]: (state) => {
    state.agentRule.updating = false
  },
  [mutationTypes.UPDATING_AGENT_RULE_ERROR]: (state) => {
    state.agentRule.error = true
    state.agentRule.updating = false
  },

  [mutationTypes.SET_DIRECTOR_RULES]: (state, payload) => {
    state.directorRules = payload
  },

  [mutationTypes.SET_PRESENTATION_RULES]: (state, payload) => {
    state.presentationRules.data = payload
  },
  [mutationTypes.DELETING_PRESENTATION_PENDING]: (state, id) => {
    state.presentationRules.data = state.presentationRules.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDeleting: true,
        }
      }
      return el
    })
  },
  [mutationTypes.DELETING_PRESENTATION_SUCCESS]: (state, id) => {
    state.presentationRules.data = state.presentationRules.data.filter(
      (el) => el.id !== id
    )
  },
  [mutationTypes.DELETING_PRESENTATION_ERROR]: (state, id) => {
    state.presentationRules.data = state.presentationRules.data.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          isDeleting: false,
        }
      }
      return el
    })
  },
  [mutationTypes.CREATING_PRESENTATION_SUCCESS]: (state, payload) => {
    state.presentationRules.data = [...state.presentationRules.data, payload]
  },

  [mutationTypes.UPDATING_PRESENTATION_SUCCESS]: (state, payload) => {
    state.presentationRules.data = state.presentationRules.data.map((el) => {
      if (el.id === payload.id) {
        return payload
      }
      return el
    })
  },

  [mutationTypes.SET_DIRECTOR_RULE]: (state, payload) => {
    state.directorRules = state.directorRules.map((el) => {
      if (el.id === payload.id) {
        return payload
      }

      return el
    })
    state.directorRule = payload
  },
  [mutationTypes.SET_RULE]: (state, payload) => {
    state.directorRule = {
      ...state.directorRule,
      rules: {
        ...state.directorRule.rules,
        [payload.type]: payload,
      },
    }
  },
  [mutationTypes.SET_RULES_METADATA]: (
    state,
    { ruleType, metadataByEntity }
  ) => {
    state.rulesMetadata = {
      ...state.rulesMetadata,
      [ruleType]: {
        ...state.rulesMetadata[ruleType],
        ...metadataByEntity,
      },
    }
  },
  [mutationTypes.SET_SEGMENTATION_REPORTS](state, data) {
    state.segmentationReports = data
  },
  [mutationTypes.SET_CONVERT_LEAD_STATUSES](state, data) {
    state.convertLeadStatuses = data
  },
  [mutationTypes.SET_OPERATORS](state, data) {
    state.operators = data
  },
}
