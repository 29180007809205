import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  user: null,
  jwt: localStorage.getItem('jwt'),
  contextMessage: undefined,
  isSessionExpired: false,
  lastRequestTimestamp: null,
  isHerokuRequired: true,
  isStepperFinished: true,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
