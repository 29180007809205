import introJs from 'intro.js/intro.js'
import i18n from '@/plugins/i18n'

export const intro = introJs()

export const introMergeOptions = {
  steps({
    hasLockedColumns = false,
    conflictsCount = 0,
    hasMandatoryFields = false,
    isFromCache = false,
    isMergeButtonVisible = false,
    isSendToAdminButtonVisible = false,
  } = {}) {
    const steps = [
      {
        title: 'Duplicate grid',
        intro:
          'Duplicate Grid is one of the main features of CleanData. The duplicate grid is used to manage duplicate records, resolve data conflicts, and determine which record and record values should be retained once the merge is completed.',
        element: document.querySelector('.dupe-grid-container'),
      },
      {
        title: 'Notifications',
        intro:
          'The notification section shows all historical changes made by all of the users or the system on the selected duplicate set.',
        element: document.getElementById('dupegrid_notifications'),
      },
      {
        title: 'Support Menu',
        intro:
          'The help icon offers a set of helpful links as well as a tutorial and support feature.',
        element: document.getElementById('dupegrid_menu'),
      },
      {
        title: 'Duplicate Set Name',
        intro:
          'This is name assigned by the system to the current duplicate set.',
        element: document.querySelector('[data-tt=dupeSetName]'),
      },
      {
        title: 'Match Confidence Score',
        intro:
          'The MCS gives an idea of the confidence the system has to merge the duplicate set. This value is the lowest value present in the records displayed in the grid.',
        element: document.querySelector('[data-tt=mcs]'),
      },
      {
        title: 'Duplicates',
        intro:
          'This section represents the number of duplicates displayed in the grid.',
        element: document.querySelector('[data-tt=duplicatesNumber]'),
      },
      {
        title: 'Conflicts',
        intro:
          'This section shows the total number of conflicts found in the duplicate set.',
        element: document.querySelector('[data-tt=conflictsNumber]'),
      },
      {
        title: 'Time to solve',
        intro:
          'This section shows the potential amount of time needed to solve the conflicts and merge the duplicate set.',
        element: document.querySelector('[data-tt=timeToSolve]'),
      },
      {
        title: 'Conflicts Filter',
        intro:
          'This checkbox will show only columns with conflicts for quicker reviewing.',
        element: document.querySelector('[data-tt=conflictsFilter]'),
      },
      {
        title: 'Primary columns',
        intro:
          "The Primary columns indicate which record is currently set as the Primary record. CleanData automatically suggests the primary record based on the set preconditions. Simply click on a radio button on this column to change the system's selected primary record.",
        element: document.querySelector('#dupegrid_grid .is-primary'),
      },
      {
        title: 'Exclude record',
        intro:
          'This icon allows to choose whether the record should be excluded from the merge. There are three options available: include a record in the merge, temporarily exclude the record from the current dupe set, and permanently exclude the record from merging.',
        element: document.querySelector('[data-tt=excludeButton]'),
      },
      {
        title: 'Details page',
        intro: "This icon allows to open the record's details page",
        element: document.querySelector('[data-tt=openDetailsPage]'),
      },
    ]

    if (hasLockedColumns) {
      steps.push({
        title: 'Locked columns',
        intro:
          'Locked columns does not allow any modification through the CleanData UI.',
        element: document.querySelector('[data-tt=lockedColumn]'),
      })
    }

    if (conflictsCount > 0) {
      steps.push({
        title: 'Conflict columns',
        intro:
          'These are the fields with different values in each record. The system automatically selects a value based on existing conditions for the conflicted field. Manual review is recommended to ensure that correct value for the field is selected. Simply click a radio button of the field value to be retained.',
        element: document.querySelector('[data-tt=conflictedColumn]'),
      })
    }

    steps.push(
      {
        title: 'Final merge values',
        intro:
          'This row displays the final merge values of a record that will be saved to the surviving record after the merge is performed. It automatically shows the values that are currently selected for conflicted fields, and will change if a different value is selected.',
        element: document.querySelector('[data-tt=finalRecord]'),
      },
      {
        title: 'Editable values',
        intro:
          'Certain fields allow for manual input of new values. This is used to change the field value to a new one that is not in the current field values of the duplicate records.',
        element: document.querySelector('[data-tt=editableCell]'),
      }
    )

    if (hasMandatoryFields) {
      steps.push({
        title: 'Mandatory Columns',
        intro: 'These columns must have a value to proceed with merging.',
        element: document.querySelector('[data-tt=mandatoryField]'),
      })
    }

    steps.push({
      title: 'Metadata',
      intro:
        'This section shows other additional useful information about the duplicate set.',
      element: document.querySelector('[data-tt=metadata]'),
    })

    if (isFromCache) {
      steps.push({
        title: 'Cache indicator',
        intro:
          'This icon is shown when a duplicate set is loaded from cache, which substantially decreases the loading time.',
        element: document.querySelector('[data-tt=cacheIndicator]'),
      })
    }

    if (isMergeButtonVisible) {
      steps.push({
        title: 'Merge',
        intro:
          'The Merge button starts the merge process and sends the duplicate set to the merge queue.',
        element: document.querySelector('[data-tt=mergeButton]'),
      })
    }

    if (isSendToAdminButtonVisible) {
      steps.push({
        title: 'Send To Admin',
        intro:
          'Send to Admin button assigns the duplicate set to the Admin for review.',
        element: document.querySelector('[data-tt=sendToAdmin]'),
      })
    }

    return steps
  },
}

export function introFieldSettingsSteps() {
  return [
    {
      title: 'Duplicate sets by object Tile',
      intro: i18n.t('intro.system_performance_description'),
      element: document.getElementById('systemPerformanceTile'),
    },
    {
      title: 'On Your Plate',
      intro:
        ' This tile shows the total number of detected duplicate sets. Clicking the My Tasks button takes you to the On My Plate Dashboard',
      element: document.getElementById('onYourPlateTile'),
    },
  ]
}

export function introActivePrimeDashboardSteps() {
  return [
    {
      title: 'Selected Objects',
      intro:
        'Objects selected by you from the ones detected in your Heroku Postgres DB',
      element: document.getElementById('detectedObjects'),
    },
    {
      title: 'Field Name',
      intro: 'Database Field Name: The name of the field in your Heroku DB',
      element: document.getElementById('fieldName'),
    },
    {
      title: 'Field Type',
      intro: 'Database Field Type: The type of the field in your Heroku DB',
      element: document.getElementById('fieldType'),
    },
    {
      title: 'Semantic Type',
      intro:
        'The Semantic Type of a Field is used with the ActivePrime domain knowledge to improve matching. It is not mandatory, and a default value could be already detected by the system.',
      element: document.getElementById('semanticType'),
    },
    {
      title: 'Read',
      intro:
        'If enabled, the field will be read by CleanData and considered for further possible configurations.',
      element: document.getElementById('read'),
    },
    {
      title: 'Use for Searching',
      intro:
        'If enabled, the field can be used for segmentation and/or matching',
      element: document.getElementById('useForSearching'),
    },
    {
      title: 'Use for Merging',
      intro: 'If enabled, it allows CleanData to write to that field',
      element: document.getElementById('useForMerging'),
    },
    {
      title: 'Configure all objects',
      intro: `Upon the completion of the selected object, you can add more objects until you are happy with the configuration.`,
      element: document.getElementById('addObjects'),
    },
  ]
}

export const introHistoryOptions = {
  steps() {
    return [
      {
        title: 'Record audit trail',
        intro:
          'Record Audit Trail shows the history of the merge process, displaying the original duplicate set with marked surviving values.',
        element: document.getElementById('dupegrid_grid'),
      },
      {
        title: 'Undo merge tutorial',
        intro:
          'If you need to undo a merge, by clicking here and then "How to undo a merge", you can access a video tutorial on how to undo a merge in Salesforce.',
        element: document.getElementById('dupegrid_menu'),
      },
      {
        title: 'Final merge values',
        intro:
          'This row displays the final merge values that were saved to the surviving record after the merge was performed.',
        element: document.querySelector('[data-tt=finalRecord]'),
      },
    ]
  },
}

export function introSystemPerformanceDashboardSteps() {
  return [
    {
      title: i18n.t('intro.duplicate_sets_by_object_title'),
      intro: i18n.t('intro.duplicate_sets_by_object_description'),
      element: document.getElementById('dupObjectTile'),
    },
    {
      title: 'Merged Duplicate Sets tile',
      intro:
        'This tile shows the total number of merged duplicate sets broken down by user types that have performed the merge. By clicking on the See All button you can navigate to the Merged Duplicate Sets table where you can review all merge record audit trails.',
      element: document.getElementById('mergeDuplicateSetsTile'),
    },
    {
      title: 'See All Duplicate Sets',
      intro:
        'By clicking on the See All button you can navigate to the Merged Duplicate Sets table where you can review all merge record audit trails.',
      element: document.getElementById('btnSeeAllDuplicateSets'),
    },
  ]
}

export function introOnYourPlateSteps() {
  return [
    {
      title: 'Duplicate sets to be reviewed tile',
      intro: i18n.t('intro.duplicate_sets_to_be_reviewed_description'),
      element: document.getElementById('reviewDuplicateSetsTile'),
    },
    {
      title: 'Potential duplicates for automation tile',
      intro:
        'This tile shows the total number of duplicate sets that have met the conditions for auto-merging. Clicking on the See All button takes you to the Potential duplicates for automation table where you can review all the sets and send the records to the ActivePrime bot to perform the merge for you.',
      element: document.getElementById('reviewAutoMergesTile'),
    },
  ]
}

export function introAutoMergeReviewTableSteps() {
  return [
    {
      title: 'Auto-merges table',
      intro:
        'This table contains all the duplicate sets that have passed the conditions for the auto-merge functionality. Clicking on any set will display the duplicate records contained in the duplicate set as well as the merge rules that will be applied to the surviving record. You can navigate through the table by using the pagination buttons below.',
      element: document.querySelector('.v-data-table'),
    },
    {
      title: 'Search field',
      intro:
        'First you need to click which column you would like to sort / search by and then input keywords into the search field.',
      element: document.querySelector('.v-text-field'),
    },
    //{
    //  title: 'Exclude from auto-merge',
    //  intro: 'The exclude from auto-merge button opens up a confrimation to avoid the auto-merge and if you click on the confirm, it will stop the selected sets from auto-merge.',
    //  element: document.querySelector('.text-right.text-no-wrap .v-btn.v-btn--outlined'),
    //},
    //{
    //  title: 'Auto-merge selected',
    //  intro: 'The auto-merge selected button opens up a confrimation to merge the record and if you click on the confirm, it will merge the duplicate set and it can only be undone by following the manual process of undoing merges from the CRM.',
    //  element: document.querySelector('.text-right.text-no-wrap .v-btn.v-btn--is-elevated'),
    //},
    {
      title: 'Checkboxes',
      intro:
        'By ticking one of the checkboxes you can select specific duplicate sets on which you want to perform actions. When a duplicate set is checked, you have the option to either auto-merge selected duplicate sets or exclude them from being merged.',
      element: document.querySelector('tr td .v-simple-checkbox'),
    },
  ]
}

export function introDuplicateSetsReviewTableSteps() {
  return [
    {
      title: 'Duplicate sets table',
      intro:
        'Duplicate sets table contains all the duplicate sets currently found by the CleanData application. Clicking on any set will display the duplicate records contained in the duplicate set as well as the merge rules that will be applied to the surviving record. You can navigate through the table by using the pagination buttons below.',
      element: document.querySelector('.v-data-table'),
    },
    {
      title: 'Search field',
      intro:
        'First you need to click which column you would like to sort / search by and then input keywords into the search field.',
      element: document.querySelector('.v-text-field'),
    },
    {
      title: 'Review All',
      intro:
        'The Review All button opens up a queue of all available duplicate sets that need to be reviewed. You can navigate between the duplicate sets using left and right arrows in the top right corner.',
      element: document.querySelector(
        '.text-right.text-no-wrap .v-btn.v-btn--is-elevated'
      ),
    },
    {
      title: 'Checkboxes',
      intro:
        'By ticking one of the checkboxes you can select specific duplicate sets on which you want to perform actions. When a duplicate set is checked, you have the option to either click the Review selected button which opens up a modal where you can review selected duplicate sets, or exclude selected which excludes selected sets from being merged. Removing a duplicate set will mean that you consider the selected set as unique records, and will be removed from the table.',
      element: document.querySelector('tr td .v-simple-checkbox'),
    },
  ]
}

export function introMergeDuplicateSetsReviewTableSteps() {
  return [
    {
      title: 'Merge Duplicate sets table',
      intro:
        'Merge Duplicate sets table contains all the merged duplicate sets found by the CleanData application. Clicking on any set will display the duplicate records contained in the duplicate set as well as the merge rules that will be applied to the surviving record. You can navigate through the table by using the pagination buttons below.',
      element: document.querySelector('.v-data-table'),
    },
    {
      title: 'Search field',
      intro:
        'First you need to click which column you would like to sort / search by and then input keywords into the search field.',
      element: document.querySelector('.v-text-field'),
    },
    {
      title: 'Export Data',
      intro:
        'The Export Data button will allow you to export the merged duplicate sets in the CSV format.',
      element: document.querySelector(
        '.text-right.text-no-wrap .v-btn.v-btn--outlined'
      ),
    },
    {
      title: 'Review All',
      intro:
        'The Review All button opens up a queue of all available duplicate sets that need to be reviewed. You can navigate between the duplicate sets using left and right arrows in the top right corner.',
      element: document.querySelector(
        '.text-right.text-no-wrap .v-btn.v-btn--is-elevated'
      ),
    },
    {
      title: 'Checkboxes',
      intro:
        'By ticking one of the checkboxes you can select specific duplicate sets on which you want to perform actions. When a duplicate set is checked, you have the option to either click the Review selected button which opens up a modal where you can review selected duplicate sets, or exclude selected which excludes selected sets from being merged. Removing a duplicate set will mean that you consider the selected set as unique records, and will be removed from the table.',
      element: document.querySelector('tr td .v-simple-checkbox'),
    },
  ]
}
