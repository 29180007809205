import mutationTypes from './mutation-types'
import { getMe, getStepperStatus } from '@/api/admin/users'
import { signIn } from '@/api/auth'

export default {
  setJwt({ commit }, jwt) {
    commit(mutationTypes.SET_JWT, jwt)
  },
  setContextMessage({ commit }, message) {
    commit(mutationTypes.SET_CONTEXT_MESSAGE, message)
  },
  signOut({ commit }) {
    commit(mutationTypes.SIGN_OUT)
  },
  setSessionExpired({ commit }, value) {
    commit(mutationTypes.SET_SESSION_EXPIRED, value)
  },
  setLastRequestTimestamp({ commit }, value) {
    commit(mutationTypes.SET_LAST_REQUEST_TIMESTAMP, value)
  },
  async getStepper({ commit }) {
    const response = await getStepperStatus()

    commit(mutationTypes.SET_STEPPER, response)
  },
  async getMe({ commit }) {
    const response = await getMe()

    commit(mutationTypes.SET_CURRENT_USER, response)
  },
  async signIn({ commit, dispatch }, { username, password }) {
    try {
      const jwt = await signIn({
        username,
        password,
      })

      await dispatch('getMe')
      commit(mutationTypes.SET_JWT, jwt)
    } catch (error) {
      dispatch(
        'app/setCurrentError',
        { message: error.message },
        { root: true }
      )
      throw error
    }
  },
  track({ state }, event) {
    window.plausible(event, {
      props: { u: state.contextMessage.userId },
    })
  },
}
