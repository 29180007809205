import mutationTypes from './mutation-types'

export default {
  [mutationTypes.SHOW_TOAST]: (state, toast) => {
    const { color, timeout, message } = toast

    state.isToastVisible = true
    state.toast = {
      message,
      color,
      timeout,
      show: true,
    }
  },
  [mutationTypes.HIDE_TOAST]: (state) => {
    state.isToastVisible = false
  },
  SNACKBAR_UPDATED,
  SNACKBAR_CLEANED,
}

function SNACKBAR_UPDATED(state, { type, message }) {
  if (type) {
    state.snackbars.push({
      id: state.snackbars.length + 1,
      type,
      icon: type === 'success' ? '$mdi-check' : '$mdi-alert-circle-outline',
      textColor: type === 'success' ? '#417505' : '#b81a0a',
      backgroundColor: type === 'success' ? '#d9e3cd' : '#f1d1ce',
      message,
    })
    // state.snackbar.type = type
    // state.snackbar.icon =
    //   type === 'success' ? '$mdi-check' : '$mdi-alert-circle-outline'
    // state.snackbar.textColor = type === 'success' ? '#417505' : '#b81a0a'
    // state.snackbar.backgroundColor = type === 'success' ? '#d9e3cd' : '#f1d1ce'

    // state.snackbar.messages = Array.isArray(message) ? message : [message]
  }
}

function SNACKBAR_CLEANED(state, id) {
  state.snackbars = state.snackbars.filter((el) => el.id !== id)
  // state.snackbar.type = undefined
  // state.snackbar.icon = undefined
  // state.snackbar.textColor = undefined
  // state.snackbar.backgroundColor = undefined
  // state.snackbar.messages = undefined
}
